// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../../../../utils/Url.res.js";
import * as $$Date from "../../../../../libs/Date.res.js";
import * as Badge from "../../../../../styleguide/components/Badge/Badge.res.js";
import * as React from "react";
import * as IconView from "../../../../../styleguide/icons/IconView.res.js";
import * as DashboardTabsCss from "../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabsHighlight from "../../../../../styleguide/dashboard/components/tabs/components/DashboardTabsHighlight.res.js";

function reducer(state, action) {
  if (action === "SetUsernameVisibility") {
    var match = state.usernameVisibility;
    var tmp;
    tmp = match === "Text" ? "Password" : "Text";
    return {
            usernameVisibility: tmp,
            passwordVisibilty: state.passwordVisibilty
          };
  }
  var match$1 = state.passwordVisibilty;
  var tmp$1;
  tmp$1 = match$1 === "Text" ? "Password" : "Text";
  return {
          usernameVisibility: state.usernameVisibility,
          passwordVisibilty: tmp$1
        };
}

var initialState = {
  usernameVisibility: "Password",
  passwordVisibilty: "Password"
};

function DashboardTsdShowDetails(props) {
  var tsd = props.tsd;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = tsd.description;
  var tmp = match$1 === "" ? "No description available." : tsd.description;
  var match$2 = tsd.homeUrl;
  var tmp$1 = match$2 === "" ? "Not available." : JsxRuntime.jsx("a", {
          children: tsd.homeUrl,
          className: DashboardTabsCss.Tab.$$Highlight.link,
          href: Url.Helper.formatUrl(tsd.homeUrl)
        });
  var match$3 = tsd.adminUrl;
  var tmp$2 = match$3 === "" ? "Not available." : JsxRuntime.jsx("a", {
          children: tsd.adminUrl,
          className: DashboardTabsCss.Tab.$$Highlight.link,
          href: Url.Helper.formatUrl(tsd.adminUrl)
        });
  var match$4 = state.usernameVisibility;
  var tmp$3;
  tmp$3 = match$4 === "Text" ? tsd.username : "•••••••••••••";
  var match$5 = state.passwordVisibilty;
  var tmp$4;
  tmp$4 = match$5 === "Text" ? tsd.encryptedPassword : "•••••••••••••";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                "Contract Started:\n           " + $$Date.Helpers.formatMonthDayYear(tsd.contractStart),
                                tsd.active ? JsxRuntime.jsx(Badge.Span.make, {
                                        id: "tsdActive",
                                        color: "Green",
                                        children: "Active"
                                      }) : JsxRuntime.jsx(Badge.Span.make, {
                                        id: "tsdActive",
                                        color: "Gray",
                                        children: "Inactive"
                                      }),
                                tsd.contractAttached ? JsxRuntime.jsx(Badge.Span.make, {
                                        id: "tsdContractDownload",
                                        color: "Blue",
                                        children: JsxRuntime.jsx("a", {
                                              children: "Download",
                                              href: tsd.contractDownload,
                                              target: "_blank"
                                            })
                                      }) : JsxRuntime.jsx(Badge.Span.make, {
                                        id: "tsdContractDownload",
                                        color: "Blue",
                                        enabled: false,
                                        children: "Download"
                                      })
                              ],
                              className: DashboardTabsCss.Tab.Section.dateDetails
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "About",
                                      className: DashboardTabsCss.Tab.label
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: tmp,
                                      className: DashboardTabsCss.Tab.Section.p
                                    })
                              ],
                              className: DashboardTabsCss.Tab.Section.description
                            })
                      ],
                      className: DashboardTabsCss.Tab.Section.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Administrative Details",
                              className: DashboardTabsCss.Tab.label
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      background: "Gray",
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Home Page",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: tmp$1,
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Admin Page",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: tmp$2,
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      background: "Gray",
                                      children: [
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                "Username",
                                                JsxRuntime.jsx(IconView.make, {
                                                      size: "SM",
                                                      color: "Teal"
                                                    })
                                              ],
                                              className: DashboardTabsCss.Tab.$$Highlight.passwordLabel,
                                              id: "tsdUsernameLabel",
                                              onClick: (function (param) {
                                                  dispatch("SetUsernameVisibility");
                                                })
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: tmp$3,
                                              className: DashboardTabsCss.Tab.$$Highlight.data,
                                              id: "tsdUsername"
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      children: [
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                "Password",
                                                JsxRuntime.jsx(IconView.make, {
                                                      size: "SM",
                                                      color: "Teal"
                                                    })
                                              ],
                                              className: DashboardTabsCss.Tab.$$Highlight.passwordLabel,
                                              id: "tsdPasswordLabel",
                                              onClick: (function (param) {
                                                  dispatch("SetPasswordVisibility");
                                                })
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: tmp$4,
                                              className: DashboardTabsCss.Tab.$$Highlight.data,
                                              id: "tsdPassword"
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      background: "Gray",
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Total Agencies",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: String(tsd.agenciesCount),
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    })
                              ],
                              className: DashboardTabsCss.Tab.$$Highlight.highlightsContainer
                            })
                      ],
                      className: DashboardTabsCss.Tab.Section.container
                    })
              ],
              className: DashboardTabsCss.Tab.container
            });
}

var Css;

var Hightlight;

var make = DashboardTsdShowDetails;

export {
  Css ,
  Hightlight ,
  reducer ,
  initialState ,
  make ,
}
/* Date Not a pure module */
