// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as Routes_Tsd from "../../../../routes/common/Routes_Tsd.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DashboardCss from "../../../../styleguide/dashboard/components/views/common/DashboardCss.res.js";
import * as DashboardTabs from "../../../../styleguide/dashboard/components/tabs/DashboardTabs.res.js";
import * as DashboardShowCss from "../../../../styleguide/dashboard/components/views/show/DashboardShowCss.res.js";
import * as DashboardTabBody from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabBody.res.js";
import * as DashboardTabMenu from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabMenu.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTsdNavbar from "../common/navbar/DashboardTsdNavbar.res.js";
import * as DashboardTabMenuItem from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabMenuItem.res.js";
import * as DashboardTsdShowDetails from "./components/DashboardTsdShowDetails.res.js";
import * as DashboardTsdShowAgencies from "./components/DashboardTsdShowAgencies.res.js";

function reducer(_state, action) {
  return {
          currentTab: action._0
        };
}

var initialState = {
  currentTab: "Details"
};

function DashboardTsdShow$default(props) {
  var tsd = props.tsd;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.currentTab;
  var tmp;
  tmp = match$1 === "Details" ? "Selected" : "NotSelected";
  var match$2 = state.currentTab;
  var tmp$1;
  tmp$1 = match$2 === "Details" ? "NotSelected" : "Selected";
  var match$3 = state.currentTab;
  var tmp$2;
  tmp$2 = match$3 === "Details" ? JsxRuntime.jsx(DashboardTsdShowDetails.make, {
          tsd: tsd
        }) : JsxRuntime.jsx(DashboardTsdShowAgencies.make, {
          tsd: tsd
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardTsdNavbar.make, {
                      currentLink: "Show",
                      tsdId: Caml_option.some(tsd.id),
                      tsdName: tsd.name
                    }),
                JsxRuntime.jsx(Container.make, {
                      className: DashboardCss.container,
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsxs("h1", {
                                                  children: [
                                                    JsxRuntime.jsx("span", {
                                                          children: "TSD Details",
                                                          className: DashboardShowCss.titleSpan
                                                        }),
                                                    tsd.name
                                                  ],
                                                  className: DashboardShowCss.title
                                                }),
                                            className: DashboardShowCss.heading
                                          }),
                                      JsxRuntime.jsx("a", {
                                            children: "Edit",
                                            className: DashboardShowCss.editLink,
                                            href: Routes_Tsd.Dashboard.edit(tsd.id)
                                          })
                                    ],
                                    className: DashboardShowCss.header
                                  }),
                              JsxRuntime.jsxs(DashboardTabs.make, {
                                    children: [
                                      JsxRuntime.jsxs(DashboardTabMenu.make, {
                                            children: [
                                              JsxRuntime.jsx(DashboardTabMenuItem.make, {
                                                    title: "Details",
                                                    tabType: tmp,
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SetCurrentTab",
                                                              _0: "Details"
                                                            });
                                                      })
                                                  }),
                                              JsxRuntime.jsx(DashboardTabMenuItem.make, {
                                                    title: "Agencies",
                                                    tabType: tmp$1,
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SetCurrentTab",
                                                              _0: "Agencies"
                                                            });
                                                      })
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(DashboardTabBody.make, {
                                            children: tmp$2
                                          })
                                    ]
                                  })
                            ],
                            className: DashboardCss.bodyContainer
                          })
                    })
              ],
              className: DashboardCss.pageContainer
            });
}

var Css;

var Tabs;

var TabMenu;

var TabMenuItem;

var TabBody;

var $$default = DashboardTsdShow$default;

export {
  Css ,
  Tabs ,
  TabMenu ,
  TabMenuItem ,
  TabBody ,
  reducer ,
  initialState ,
  $$default as default,
}
/* react Not a pure module */
